<template>
  <NavCheckAndConfirm :text="profileDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'ProfileCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    start() {
      return this.$router.history.current.name === 'WriteMyWillCheckAndConfirm'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    backTo() {
      if (
        this.personalDetails &&
        this.personalDetails.marital_status === 'Single' &&
        this.start
      )
        return '/write_my_will/marital_status'
      else if (
        this.personalDetails &&
        this.personalDetails.marital_status === 'Single'
      )
        return '/profile/marital_status'
      else if (
        (this.personalDetails.marital_status === 'Separated' ||
          this.personalDetails.partner) &&
        this.start
      )
        return '/write_my_will/partner_gender'
      else if (
        this.personalDetails.marital_status === 'Separated' ||
        this.personalDetails.partner
      )
        return '/profile/partner_gender'
      else if (this.start) return '/write_my_will/will_for'
      return '/profile/will_for'
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveDetails')
    this.$store.dispatch('savePersonalDetails')
    this.$notification.success('Profile details saved')
    next()
  }
}
</script>

<style></style>
